import './App.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Header from './Header';

function OurStory() {

  return (
    <Container>
      <Header />

      <Card
        bg="Light"
        text='dark'
        style={{ width: '100%', maxWidth: "100%" }}
        className="mb-2"
      >
          <Card.Header>
            Our Story
          </Card.Header>
          <Card.Body>
            <Card.Text as="div" style={{fontSize:"1.3 rem"}}>
              <img src="/50946200_2305062486397417_3713112666068221952_n.jpg" style={{"float":"right","width":"30%","maxWidth":"30%"}} alt="TV News crew interviewing Hate Free Schools members outside a school board meeting" />

              <p>
              We are a grassroots alliance working toward racial equity in public schools and
              communities throughout the nation. 
              </p>
 
              <p>
              The coalition demands a school system where all children are safe to learn and grow,
              without racial and ethnic intimidation. Racial and ethnic intimidation is occurring and
              persists routinely, as incidents have increased so much that it is immeasurably
              normalized. Our stance is that even one time is too many!
              </p>

              <p>
              Together, we strive to simply educate and promote racial equity, throughout our schools
              and communities; and ultimately to create change in our schools, from the ground up. We do
              this by responding immediately to this hostile climate, and effectively acting to create a
              safe environment where students can thrive. We stand in solidarity with all marginalized
              groups; we stand opposed to all forms of inequity. We further acknowledge the increased
              vulnerability faced by people at the intersection of multiple oppressions. The coalition
              is a collective voice of truth and power for all families.
              </p>


            </Card.Text>
          </Card.Body>
                    
      </Card>

    </Container>
  );
}

export default OurStory;