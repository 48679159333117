import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Header() {
  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom" style={{"width":"100%"}}>
      <Container>
        <Row>
          <Col>
            <img src="/logo-full.png" alt="logo" />
          </Col>
          <Col className="me-auto text-end">
            <h1 className="h2">Hate-Free Schools Coalition</h1>          
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;