import './App.css';
import { useDataStore } from "./DataStoreProvider";
import Container from 'react-bootstrap/Container';
import PhotoAlbum from "react-photo-album";

function Wreaths() {

  const photos = [
      { src: "/wreath-laying/_DSC1011.JPG", width: 900, height: 644 },
      { src: "/wreath-laying/_DSC1046.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1068.JPG", width: 450, height: 676 },
      { src: "/wreath-laying/_DSC1073.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1077.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1098.JPG", width: 900, height: 635 },
      { src: "/wreath-laying/_DSC1120.JPG", width: 900, height: 614 },
      { src: "/wreath-laying/_DSC1136.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1147.JPG", width: 630, height: 946 },
      { src: "/wreath-laying/_DSC1150.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1169.JPG", width: 900, height: 613 },
      { src: "/wreath-laying/_DSC1189.JPG", width: 578, height: 900 },
      { src: "/wreath-laying/_DSC1199.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1214.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1215.JPG", width: 900, height: 640 },
      { src: "/wreath-laying/_DSC1217.JPG", width: 900, height: 618 },
      { src: "/wreath-laying/_DSC1219.JPG", width: 900, height: 677 },
      { src: "/wreath-laying/_DSC1237.JPG", width: 900, height: 639 }, 
      { src: "/wreath-laying/_DSC1238.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1239.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1241.JPG", width: 900, height: 620 },
      { src: "/wreath-laying/_DSC1242.JPG", width: 900, height: 622 },
      { src: "/wreath-laying/_DSC1243.JPG", width: 900, height: 680 },
      { src: "/wreath-laying/_DSC1244.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1246.JPG", width: 900, height: 574 },
      { src: "/wreath-laying/_DSC1247.JPG", width: 900, height: 589 },
      { src: "/wreath-laying/_DSC1251.JPG", width: 900, height: 592 },
      { src: "/wreath-laying/_DSC1252.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1253.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1254.JPG", width: 900, height: 596 },
      { src: "/wreath-laying/_DSC1256.JPG", width: 900, height: 558 },
      { src: "/wreath-laying/_DSC1257.JPG", width: 900, height: 558 },
      { src: "/wreath-laying/_DSC1262.JPG", width: 646, height: 900 },
      { src: "/wreath-laying/_DSC1264.JPG", width: 900, height: 599 }, 
      { src: "/wreath-laying/_DSC1285.JPG", width: 900, height: 612 },
      { src: "/wreath-laying/_DSC1299.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1300.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/_DSC1302.JPG", width: 900, height: 600 },
      
      { src: "/wreath-laying/_DSC1305.JPG", width: 900, height: 620 },
      { src: "/wreath-laying/_DSC1307.JPG", width: 900, height: 611 },
      { src: "/wreath-laying/_DSC1309.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/DSC_3654.JPG", width: 632, height: 900 },
      { src: "/wreath-laying/DSC_3715.JPG", width: 599, height: 900 },
      { src: "/wreath-laying/DSC_3799.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/DSC_3815.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/DSC_3823.JPG", width: 900, height: 599 },
      { src: "/wreath-laying/DSC_3843.JPG", width: 599, height: 900 },
      { src: "/wreath-laying/DSC_3849.JPG", width: 580, height: 900 },
  ];

  return (
    <Container>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom" style={{"width":"100%"}}>
        <img src="/logo-full.png" alt="logo"  /><br />
        <h1 className="h2">Hate-Free Schools Coalition, Orange County </h1>
      </div>


    </Container>
  );
}

export default Wreaths;