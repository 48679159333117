import './App.css';
import { useDataStore } from "./DataStoreProvider";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from "react-bootstrap/Image";
import Header from './Header';

function OurWork() {

  return (
    <Container>
      <Row>
        <Col>
          <Header />
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <Card
            bg="Light"
            key="Light"
            text='dark'
            style={{ width: '100%', "max-width": "100%" }}
            className="mb-2"
          >
            <Card.Header>
              <strong>Our Work : Core Team</strong>
            </Card.Header>
            <Card.Body>
              <Card.Text as="div" style={{fontSize:"1.2 rem"}}>
                Small group of  individuals that serve as the steering committee for HFS
                (the brains of the organization). The core team is responsible for the
                decision making process, planning, and strategizing. Building coalition is
                key, and we do this by: establishing partnerships within the community, to
                address racism, promote equity, model anti-racist living.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card
            bg="Light"
            key="Light"
            text='dark'
            style={{ width: '100%', "max-width": "100%" }}
            className="mb-2"
          >
            <Card.Header>
              <strong>Our Work : Action Group</strong>
            </Card.Header>
            <Card.Body>
              <Card.Text as="div" style={{fontSize:"1.2 rem"}}>
                <img src="/67254912_2417434168493581_6586944677608423424_n.jpg" alt="several people sitting in a meeting with #HateFree taped over their mouths" style={{"float":"left",width:"40%",maxWidth:"40%",marginRight:"15px"}} />
                Small group of  individuals that serve as the steering committee for HFS
                (the brains of the organization). The core team is responsible for the
                decision making process, planning, and strategizing. Building coalition is
                key, and we do this by: establishing partnerships within the community, to
                address racism, promote equity, model anti-racist living.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Card
            bg="Light"
            key="Light"
            text='dark'
            style={{ width: '100%', "max-width": "100%" }}
            className="mb-2"
          >
            <Card.Header>
              <strong>Our Work : Engagement Team</strong>
            </Card.Header>
            <Card.Body>
              <Card.Text as="div" style={{fontSize:"1.2 rem"}}>
                A set group of 25 members that gather on a monthly basis for education,
                practice drills, workshops, and community engagement. Some of the work
                includes a deep dive into exploring systemic inequities through research,
                examination, and exposure.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card
            bg="Light"
            key="Light"
            text='dark'
            style={{ width: '100%', "max-width": "100%" }}
            className="mb-2"
          >
            <Card.Header>
              <strong>Our Work : Equity Ambassadors Association</strong>
            </Card.Header>
            <Card.Body>
              <Card.Text as="div" style={{fontSize:"1.2 rem"}}>
                School based-association with a primary goal of servicing individual
                schools, through board engagement, school board reporting, parent
                organizing, and meeting specifically to provide a safe space for students
                and parents to explicitly discuss racism, but not exclusively.
              </Card.Text>
            </Card.Body>
          </Card>  
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>        
          <Card
            bg="Light"
            key="Light"
            text='dark'
            style={{ width: '100%', "max-width": "100%" }}
            className="mb-2"
          >
            <Card.Header>
              <strong>Our Work : Black Table</strong>
            </Card.Header>
            <Card.Body>
              <Card.Text as="div" style={{fontSize:"1.2 rem"}}>
                Establish a culture of voting, while celebrating voters, doing all that we
                can within our C3 restrictions, to foster informed constituents on political
                issues. Through our "meet me at the black table initiative" we will update
                voters on all things politics, while constantly engaging voters during and
                outside election cycles.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card
            bg="Light"
            key="Light"
            text='dark'
            style={{ width: '100%', "max-width": "100%" }}
            className="mb-2"
          >
            <Card.Header>
              <strong>Our Work : Mayor Leadership Club</strong>
            </Card.Header>
            <Card.Body as="div">
              <Card.Text as="div" style={{fontSize:"1.2 rem"}}>

                <p>
                A student membership club in conjunction with the Mayor, meeting on a monthly basis for
                group visits to entities within the community. The goal of this club is to help students
                build skills through youth activities such as: racial equity training workshops,
                environmental activism, and student advocacy.  
                </p>

                <p>
                When visiting these entities, there are 3 major components of focus: 1). industry history
                &amp; development, with a racial lens, 2.) leadership building, and 3.) youth civic
                engagement
                </p>

              </Card.Text>
            </Card.Body>                    
          </Card>
        </Col>
      </Row>

    </Container>
  );
}

export default OurWork;