import './App.css';
import { useDataStore } from "./DataStoreProvider";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from "react-bootstrap/Image";
import Header from './Header';

function Home() {

  return (
    <Container>
      <Header />

      <Card
        bg="Light"
        key="Light"
        text='dark'
        style={{ width: '100%', "maxWidth": "100%" }}
        className="mb-2"
      >
          <Card.Header>
            Hate-Free Schools Coalition is a grassroots alliance of community members 
            and groups working toward racial equity in public schools and communities 
            throughout the nation.
          </Card.Header>
          <Card.Body>
            <Card.Text as="div" style={{fontSize:"1.3 rem"}}>
              <p>
              The HFSC demands a school system where all children are safe to learn and grow.
              </p>

              <p>
              Racial and ethnic intimidation is occurring in the Orange County school system. There is a persistent pattern and incidents have increased during the presidential campaign and after the election. Even one time is too many.
              </p>

              <p>
              Racialized intimidation is not only directed at and affecting students; staff and teachers are also targets.
              </p>

              <p>
              The HFSC demands the OC School Board respond immediately to this hostile climate and act to create a climate of equity.
              </p>

              <p>
              This includes passing and enforcing a ban on the confederate flag and other symbols of racial and ethnic hatred.
              </p>

              <ul>
                <li>The flag is disruptive and harmful to the learning environment and school climate.</li>
                <li>The Klan uses the flag as its primary symbol. Confederate flags are used locally by the Klan. These are photos of the cars in the recent Klan car caravan in Roxboro adorned with confederate battle flags. After the confederate flag rally in Hillsborough last August, white men drove through black neighborhoods in northern Hillsborough yelling "white power!" while waving confederate flags. It's clear: the flag is a racist symbol of intimidation and fear and the ideology of white supremacy right here in town and in Orange County.</li>
                <li>Schools across the South, including nearby in Chatham County, have banned confederate flags from school property &ldquo;because the ... [display] could result in conflict or violence on school grounds.&rdquo; A 4th circuit federal court has upheld this decision that a ban on confederate flags at schools are justified, legal, and constitutional.</li>
                <li>Students and school personnel have shared stories of racist language and graffiti.</li>
                <li>The Board must take the problem of racism in the schools seriously and take serious, immediate, and broad action to address it.            </li>
              </ul>
            </Card.Text>
          </Card.Body>
          
          <Row style={{"marginBottom":"10px"}}>
            <Col>
              <Image src="/73016707_2494700440766953_4676290018845655040_n.jpg"  style={{width: "100%","maxWidth":"100%"}} />
            </Col>
          </Row>
          
      </Card>

    </Container>
  );
}

export default Home;