import './App.css';
import { useDataStore } from "./DataStoreProvider";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Header from './Header';

function Sent() {
  return (
    <Alert variant='success'>
      Message sent, thanks!
    </Alert>   
  );
}

function SendForm() {

  const { appData, setAppData } = useDataStore();

  const formAction = (event) => {
    event.preventDefault();
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };  

  const addressLine2 = (event) => {
    event.preventDefault();
    if ("Show Line 2" === appData.line2BtnText) {
      setAppData({ ...appData, line2BtnText : "Hide Line 2" });
    }
    else {
      setAppData({ ...appData, line2BtnText : "Show Line 2" });
    }
  };
  
  const iam = (event) => {
    setAppData({ ...appData, iam : event.target.id});
  };
  
  const sendForm = (event) => {
    event.preventDefault();
    setAppData({ ...appData, loading : true });
    
    if (!appData.firstName.match(/\w/)) {
      alert('Please provide your first name');
      setAppData({ ...appData, loading : false });
      window.scrollTo(0, 0);
      return false;
    }

    if (!appData.lastName.match(/\w/)) {
      alert('Please provide your last name');
      setAppData({ ...appData, loading : false });
      window.scrollTo(0, 0);
      return false;
    }

    if (!appData.email.match(/\w/)) {
      alert('Please provide your email address');
      setAppData({ ...appData, loading : false });
      window.scrollTo(0, 0);
      return false;
    }
    
    fetch(appData.endpoint + "/message", {
      method : "POST",
      body : JSON.stringify({
        firstName : appData.firstName,
        lastName  : appData.lastName,
        email     : appData.email,
        phone     : appData.phone,
        address1  : appData.address1,
        address2  : appData.address2,
        town      : appData.town,
        state     : appData.state,
        zip       : appData.zip,
        iam       : appData.iam,
        message   : appData.message,
      })
    })
    .then((res) => {
      if (200 === res.status) { 
        setAppData({ ...appData, loading: false, appState: "message sent" });
      }
      else {
        res.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Error sending message'); 
          }
          
          setAppData({ ...appData, loading: false, appState: "error" });
        })
      }   
    })
    .catch((err) => {
      setAppData({ ...appData, loading: false, appState: "error" });
      console.log(err);
    });    
  };

  return (
    <>
      <h2>Contact Us Form</h2>
      <p>(<span style={{color:"red"}}>*</span>) items are required.</p>

      <Form.Group className="mb-3">
        <Form.Label><span style={{color:"red"}}>*</span>Your Name</Form.Label>
        <Row>
          <Col sm={12} md={6} style={{marginBottom:"5px"}}>
            <Form.Control type="text" name="firstName" placeholder="First Name" onChange={formAction} value={appData.firstName} />
          </Col>
          <Col sm={12} md={6} style={{marginBottom:"5px"}}>
            <Form.Control type="text" name="lastName" placeholder="Last Name" onChange={formAction} value={appData.lastName} />
          </Col>
        </Row>
      </Form.Group>
      
      <Form.Group className="mb-3">
        <Form.Label><span style={{color:"red"}}>*</span>Your Email Address</Form.Label>
        <Form.Control type="text" name="email" placeholder="you@example.com" onChange={formAction} value={appData.email} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Your Phone Number</Form.Label>
        <Form.Control type="text" name="phone" placeholder="(919) 000-0000" onChange={formAction} value={appData.phone} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Your Mailing Address</Form.Label>
        <Row> 
          <Col sm={12} md={10} style={{marginBottom: "5px"}}>
            <Form.Control type="text" name="address1" placeholder="101 Main Street" onChange={formAction} value={appData.address1} />
          </Col>
          <Col sm={12} md={2}style={{marginBottom: "5px"}}>
            <Button onClick={addressLine2} variant="outline-dark">
              {appData.line2BtnText}
            </Button>
          </Col>
        </Row>
        {
          ("Show Line 2" === appData.line2BtnText) ? (
            <></>
          ) : <Row>
              <Col style={{marginBottom: "5px"}}>
                <Form.Control type="text" name="address2" placeholder="Apt. 200" onChange={formAction} value={appData.address2} />
              </Col>
            </Row>
        }
        
        <Row>
          <Col sm={12} md={6}>
            <Form.Control type="text" name="town" placeholder="Hillborough" onChange={formAction} value={appData.town} />
          </Col>
          <Col sm={12} md={4}>
            <Form.Control type="text" name="state" placeholder="NC" onChange={formAction} value={appData.state} />
          </Col>
          <Col sm={12} md={2}>
            <Form.Control type="text" name="zip" placeholder="27278" onChange={formAction} value={appData.zip} />
          </Col>
        </Row>
        
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>I am&hellip;</Form.Label>
        <Form.Check 
          type="radio"
          name="iam"
          id="parent"
          label="A concerned parent"
          onChange={iam}
        />
        <Form.Check 
          type="radio"
          name="iam"
          id="press"
          label="A member of the press"
          onChange={iam}
        />
        <Form.Check 
          type="radio"
          name="iam"
          id="elected"
          label="An elected official"
          onChange={iam}
        />
        <Form.Check 
          type="radio"
          name="iam"
          id="employee"
          label="A school district employee"
          onChange={iam}
        />
        <Form.Check 
          type="radio"
          name="iam"
          id="interest"
          label="Interested in getting involved"
          onChange={iam}
        />
        <Form.Check 
          type="radio"
          name="iam"
          id="funder"
          label="A donor or potential donor"
          onChange={iam}
        />
        <Form.Check 
          type="radio"
          name="iam"
          id="other"
          label="Other"
          onChange={iam}
        />
      </Form.Group>
      
      <Form.Group className="mb-3">
        <Form.Label>Your message to us:</Form.Label>
        <Form.Control as="textarea" rows={6} name="message" onChange={formAction} value={appData.message} />      
      </Form.Group>

      <Button onClick={sendForm} variant="dark">
         {appData.loading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {!appData.loading && "Send Message"}       
      </Button>
    </>
  );
}


function ContactUs() {

  const { appData, setAppData } = useDataStore(); 

 return (
    <Container>
      <Header />

      {
        appData.appState ? <Sent /> : <SendForm />
      }

      <Card
        bg="Light"
        text='dark'
        style={{ width: '100%', maxWidth: "100%", marginTop: "20px" }}
        className="mb-2"
      >
      <Card.Body>
        <Card.Text as="div" style={{fontSize:"1.3 rem"}}>
          <Row>
            <Col sm={12} md={6} className="text-center">
              <a href="mailto:hatefreeschoolscoalition@gmail.com">hatefreeschoolscoalition@gmail.com</a>
            </Col>
            <Col sm={12} md={6} className="text-center">
              <a href="tel:+19194324747">(919) 432-4747</a>
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
                    
      </Card>

    </Container>
  );

}

export default ContactUs;