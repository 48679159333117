import { useEffect } from "react";
import DataStoreProvider, { useDataStore } from "./DataStoreProvider";
import Wrapper from "./Wrapper"

function Main() {
  const { appData, setAppData } = useDataStore();

  useEffect(() => {
    const hash = window.location.hash.substr(1);
    if (hash) {
      switch (hash) {
        case 'our-story':
          setAppData({ ...appData, page: 'our-story'});
          break;
        case 'our-work':
          setAppData({ ...appData, page : 'our-work' });
          break;
        case 'contact-us':
          setAppData({ ...appData, page : 'contact' });
          break;
        case 'wreath-laying':
          setAppData({ ...appData, page: 'wreath-laying' });
          break;
        case 'contest':
          setAppData({ ...appData, page: 'contest' });
          break;
  
        default:
          setAppData({ ...appData, page : 'home' });
      }
    }
  }, []);
  

  switch (appData.page) {
  
    default:
      return <Wrapper />;
  }
}

function App() {
  return (
    <DataStoreProvider>
      <Main />
    </DataStoreProvider>
  );
}

export default App;
