import './App.css';
import { useDataStore } from "./DataStoreProvider";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Home from "./Home";
import ContactUs from "./ContactUs";
import OurStory from "./OurStory";
import OurWork from "./OurWork";
import Wreaths from "./Wreaths";
import Contest from "./Contest";


function Content() {
  const { appData, setAppData } = useDataStore();
  
  switch (appData.page) {
    case 'our-story':
      return <OurStory />;
    case 'our-work':
      return <OurWork />;
    case 'contact':
      return <ContactUs />;
    case 'wreath-laying':
      return <Wreaths />;
    case 'contest':
      return <Contest />;
    case 'wreaths':
      return <Wreaths />;
    
    default:
      return <Home />;
  }
}

function Wrapper() {
  const { appData, setAppData } = useDataStore();
  
  const home = (event) => {
    setAppData({ ...appData, page : 'home' });
  };
  
  const ourStory = (event) => {
    setAppData({ ...appData, page : 'our-story' });
  };
  
  const ourWork = (event) => {
    setAppData({ ...appData, page : 'our-work' });
  };
  
  const contactUs = (event) => {
    setAppData({ ...appData, page : 'contact' });
  };
  
  const contest = (event) => {
    setAppData({ ...appData, page: 'contest' });
  }
    
  return (
  <>
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container>        
        <Navbar.Brand href="#home" onClick={home}>
        <img
          alt=""
          src="/logo-sm.jpg"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}        
        Hate-Free Schools Coalition</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" onClick={home} style={{"fontColor":"white"}}>Home</Nav.Link>
            <Nav.Link href="#our-story" onClick={ourStory} style={{"fontColor":"white"}}>Our Story</Nav.Link>
            <Nav.Link href="#contest" onClick={contest} style={{fontColor:"white"}}>Contest</Nav.Link>
            <Nav.Link href="#our-work" onClick={ourWork} style={{"fontColor":"white"}}>Our Work</Nav.Link>
            <Nav.Link href="#contact-us" onClick={contactUs} style={{"fontColor":"white"}}>Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      
    <Content />

  </>
  );
  
  
  
}

export default Wrapper;