import './App.css';
import { useDataStore } from "./DataStoreProvider";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from "react-bootstrap/Image";
import Header from './Header';

function Home() {

  return (
    <Container>
      <Header />

      <Card
        bg="Light"
        key="Light"
        text='dark'
        style={{ width: '100%', "maxWidth": "100%" }}
        className="mb-2"
      >
          <Card.Header style={{textAlign:"center", color:"#ED7D31", fontFamily:"Bookman Old Style,Times New Roman,Serif", fontWeight:"bold", fontSize: 48}}>
            ATTENTION ATTENTION:<br />
            MIDDLE & HIGH SCHOOLERS OF<br />
            ORANGE COUNTY, NC!!<br />
            <div style={{color:"#000000", fontStyle:"italic", marginTop:20}}>
            INTERESTED IN MAKING SOME MONEY??<br />
            </div>
            <div style={{color:"#000000", fontWeight:"normal", marginTop:20}}>
            JOIN OUR <span style={{color:"#ED7D31", fontWeight:"bold"}}>&lsquo;DESIGN A SIGN&rsquo;</span> CHALLENGE AND WIN A VISA GIFT CARD UP TO $100!
            </div>
          </Card.Header>
          <Card.Body>
            <Card.Text as="div" style={{fontSize:"1.3 rem", fontFamily:"Bookman Old Style,Times New Roman,Serif"}}>
<p style={{fontWeight:"bold",textAlign:"center"}}>
Instructions for Challenge:
</p>

<ol>
	<li><a href="https://docs.google.com/forms/d/e/1FAIpQLSddUOEFQwupoXF_CJmHqw6oa32e8yeCSgUqPi5NojofTOoGIA/viewform?usp=sf_link" target="_blank">Click this link, and complete the registration form</a></li>

	<li>Create a design celebrating black voters. Be as creative as possible (feel free to use fun colors and celebratory voting language)!</li>

	<li>Submit your design to <a href="mailto:hatefreeschoolscoaltion@gmail.com" target="_blank">hatefreeschoolscoaltion@gmail.com</a> by September 30<sup>th</sup>, 2023</li>

	<li>Share our Facebook page with your friends and family (which is where the design will be posted) <a href="https://www.facebook.com/HateFreeSchoolsOC/" target="_blank">https://www.facebook.com/HateFreeSchoolsOC/</a></li>
</ol>
<br />
<p style={{fontWeight:"bold",textAlign:"center"}}>
The Voting Process: 
</p>
<p>
All signs will be posted on our Facebook page for a poll (this is why we need you to share
our page with your family and friends; this will help to boost your votes). The contest
will take place throughout the remainder of September 2023, with the last day to submit your design
being September 30th. Voting will take place October 1-14, and winners will be announced on October 15 (although, every one of
you are winners in our eyes)! 
</p>
<br />
<p style={{fontWeight:"bold",textAlign:"center"}}>
Awards Process:
</p>
<p style={{fontWeight:"bold"}}>
There will be 3 prizes awarded. 1<sup>st</sup> place winner will receive a $100 visa gift card; 
2<sup>nd</sup>
place winner will receive a $75 visa gift card; and 3<sup>rd</sup> place winner will receive a $50
visa gift card. <span style={{color:"#ED7D31", fontWeight:"bold"}}>NOW, ON YOUR MARKS, GET SET&hellip; GO!</span>
</p>
            </Card.Text>
          </Card.Body>
                    
      </Card>

    </Container>
  );
}

export default Home;