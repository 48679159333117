import React, { createContext, useState, useContext } from "react";

const DataStore = createContext();
export const useDataStore = () => useContext(DataStore);

export default function DataStoreProvider({ children }) {

  /* change endpoint here and only hear to select version of back-end code to run against */
  const [appData, setAppData] = useState({ 
    token: null, 
    userEmail: "", 
    userPassword: "",
    userId: "",
    confirmCode : "",
    page : "home",
    endpoint : 'https://0veqlj2xeg.execute-api.us-east-1.amazonaws.com/0',
    loading : false,
    line2BtnText : "Show Line 2",
    firstName : "",
    lastName : "",
    email : "",
    address2 : "",
  });

  return (
    <DataStore.Provider value={{ appData, setAppData }}>
      {children}
    </DataStore.Provider>
  );
}